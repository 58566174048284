@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@layer components {
  html {
    font-family: "Poppins";
  }
  .mainLayout {
    @apply w-full flex flex-col bg-[#fafafa];
  }
  .heading {
    @apply font-bold text-[#2f3d55] text-center lg:text-xl text-base;
  }

  .mainContent {
    @apply font-bold tracking-wide text-black text-center text-sm;
  }

  .shortContent {
    @apply text-[#2f3d55] font-semibold text-xs;
  }

  .otpInputField {
    @apply bg-white heading w-[15%] h-[50px] outline-0 border-2 border-black;
  }

  .rowHolder {
    @apply flex flex-row justify-between;
  }

  .columnHolder {
    @apply flex flex-col;
  }

  .gridHolderCatalogue {
    @apply grid grid-cols-2 sm:grid-cols-3 landscape:lg:grid-cols-5 portrait:lg:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-5 w-full;
  }

  .gridHolderProduct {
    @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 w-full;
  }

  .gridHolderOrderDescription {
    @apply grid grid-cols-1 w-full;
  }

  .squareImages {
    @apply w-[45px] h-[45px] object-contain;
  }

  .squareIcons {
    @apply w-[20px] h-[20px] object-contain;
  }

  .rectangleButtons {
    @apply self-center bg-[#D3D3D3] p-[2%] font-semibold;
  }

  .catalogueParent {
    @apply w-full max-w-[360px] overflow-hidden min-[375px]:h-[220px] min-[425px]:h-[250px] md:h-[270px] 2xl:h-[360px] flex flex-col justify-center items-center;
  }

  .catalogueSubParent {
    @apply w-full h-full border justify-center items-center bg-white rounded-lg;
  }

  .productParent {
    @apply w-full max-w-[360px] max-h-[400px] lg:h-[400px] h-[240px] flex flex-col justify-center items-center;
  }

  .productSubParent {
    @apply w-full h-full shadow-sm flex flex-col self-center border justify-between items-center bg-white rounded-lg;
  }

  .otpInput {
    @apply outline-0 heading text-center w-[16%] h-full;
  }

  .imageHeights {
    @apply w-full min-[325px]:h-[160px] min-[375px]:h-[170px] min-[425px]:h-[200px] sm:h-[200px] 2xl:h-[300px] object-fill rounded-t-xl;
  }

  .scrollbar::-webkit-scrollbar {
    height: 0.3rem;
    border-radius: 25;
    background-color: rgb(210, 210, 210);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    height: 0.3rem;
    border-radius: 25;
    background-color: rgb(159, 158, 158);
  }

  .scrollbar1::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 25;
    background-color: rgb(210, 210, 210);
  }

  .scrollbar1::-webkit-scrollbar-thumb {
    width: 0.3rem;
    border-radius: 25;
    background-color: rgb(159, 158, 158);
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    @apply text-center mb-5 border-b-0;
  }

  th {
    @apply p-2;
  }
}
